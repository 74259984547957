import { inject } from "vue";
export const appConfig = {
    install (app) {
        app.config.unwrapInjectedRef = true;

        app.config.errorHandler = (err, instance, info) => {
            const logger = inject("vuejs3-logger");
            if(_.isFunction(logger?.error))
                logger.error(`Horizon - unhandled exception occurred :: [${info}]`, err);
            else
                console.error(`Horizon - unhandled exception occurred :: [${info}]`, err);
            return false;
        };
    }
};

export const APP_ISSUE_CODE = {
    UNAUTHORIZED: "unauthorized",
    UNHANDLED: "unhandled",
    MISSING_USER: "missing-user",
    INVALID_USER: "invalid-user",
    UNSUPPORTED_DEVICE: "unsupported-device",
    MAINTENANCE_MODE: "maintenance-moce",
    INVALID_TENANT: "invalid-tenant"
};

export class SessionInfo {
    constructor(options={}) {
        this.userSettings = options?.userSettings;
        this.token = options?.token;
        this.isValidUser = _.parseBool(options?.isValidUser);
        this.status = options?.status;
    }
}
